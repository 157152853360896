<template>
<div>
  <b-container fluid>
    <b-row class="mb-4" v-if="!permissions.workflow">
      <b-col>
        Access only for RRIC Admins
      </b-col>
    </b-row>
    <b-row v-if="loading" class="text-center" align-v="center" style="height: 80vh">
      <b-col>
        <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading & permissions.workflow">
      <b-col>
        <component v-bind:is="currentComponent"></component>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'
import workflow from '@/workflows/article'
import Approve from '@/components/workflow/article/Approve.vue'
import Delete from '@/components/workflow/article/Delete.vue'
import Edit from '@/components/workflow/article/Edit.vue'
import Publish from '@/components/workflow/article/Publish.vue'
import Review from '@/components/workflow/article/Review.vue'

export default {
  components: {
    Approve,
    Delete,
    Edit,
    Publish,
    Review
  },
  computed: {
    article: {
      get () {
        return this.$store.state.article
      },
      set (payload) {
        this.$store.commit('setArticle', payload)
      }
    },
    currentComponent: function () {
      if (this.workflowStage) {
        return workflow.find(x => x.name === this.workflowStage).component
      } else {
        return null
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    },
    workflowStage: {
      get () {
        return this.$store.state.workflowStage
      },
      set (payload) {
        this.$store.commit('setWorkflowStage', payload)
      }
    }
  },
  created: async function () {
    this.permissions.workflow = ac.can(this.user.acgroups).readAny('workflow').granted
    if (!this.permissions.workflow) {
      this.$router.push({ name: 'Start' })
    }
    const article = await this.$Amplify.API.get('cosmos', `/article/${this.$route.params.id}`)
    this.article = article
    this.workflowStage = workflow.find(x => x.id === this.article.stage).name
    this.loading = false
  },
  data () {
    return {
      loading: true,
      permissions: {}
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
