<template>
  <b-container fluid>
    <b-row v-if="loading" class="text-center" align-v="center" style="height: 80vh">
      <b-col>
        <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
      </b-col>
    </b-row>
    <b-row v-if="!loading" class="my-4">
      <b-col>
        <b-button class="mr-2" variant="outline-primary" v-on:click="closeArticle">close</b-button>
      </b-col>
    </b-row>
    <b-row class="my-2" v-if="!loading">
      <b-col v-if="permissions.editArticle">
        <input v-model="article.title" type="text" class="article_title">
      </b-col>
      <b-col v-if="!permissions.editArticle">
        <h1>{{article.title}}</h1>
      </b-col>
    </b-row>
    <b-row class="my-2" v-if="!loading">
      <b-col v-if="permissions.editArticle">
        <div class="editor">
          <div :editor="editor">
            <div class="menubar">
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().toggleBold().focus().run()">
                <div class="icon">
                  <b-icon-type-bold title="Bold"></b-icon-type-bold>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('italic') }" @click="editor.chain().toggleItalic().focus().run()">
                <div class="icon">
                  <b-icon-type-italic title="Italic"></b-icon-type-italic>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('underline') }" @click="editor.chain().toggleUnderline().focus().run()">
                <div class="icon">
                  <b-icon-type-underline title="Underline"></b-icon-type-underline>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('heading2') }" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
                <div class="icon">
                  <b-icon-type-h-2 title="Medium Heading"></b-icon-type-h-2>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('heading3') }" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
                <div class="icon">
                  <b-icon-type-h-3 title="Small Heading"></b-icon-type-h-3>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('bullet_list') }" @click="editor.chain().toggleBulletList().focus().run()">
                <div class="icon">
                  <b-icon-list-ul title="Bullet List"></b-icon-list-ul>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('ordered_list') }" @click="editor.chain().toggleOrderedList().focus().run()">
                <div class="icon">
                  <b-icon-list-ol title="Ordered List"></b-icon-list-ol>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('blockquote') }" @click="editor.chain().toggleBlockquote().focus().run()">
                <div class="icon">
                  <b-icon-chat-left-quote title="Quote"></b-icon-chat-left-quote>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('link') }" @click="setLink">
                <div class="icon">
                  <b-iconstack>
                    <b-icon-slash-circle stacked style="color: #c71426;" v-if="editor.isActive('link')" title="remove Link"></b-icon-slash-circle>
                    <b-icon-link stacked title="Link"></b-icon-link>
                  </b-iconstack>
                </div>
              </button>
              <button class="menubar__button" @click="editor.chain().undo().focus().run()">
                <div class="icon">
                  <b-icon-arrow-counterclockwise title="Undo"></b-icon-arrow-counterclockwise>
                </div>
              </button>
              <button class="menubar__button" @click="editor.chain().redo().focus().run()">
                <div class="icon">
                  <b-icon-arrow-clockwise title="Redo"></b-icon-arrow-clockwise>
                </div>
              </button>
              <button class="menubar__button" @click="saveArticle" style="width: 60px;">
                <div class="icon">
                  <span v-if="!saving">save</span>
                  <b-spinner v-if="saving" variant="success" type="grow" label="saving"></b-spinner>
                </div>
              </button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading">
      <b-col v-if="permissions.editArticle">
        <div class="editor">
          <editor-content class="editor__content" :editor="editor" />
        </div>
      </b-col>
      <b-col v-if="!permissions.editArticle">
        <div v-html="article.body"></div>
      </b-col>
      <b-col cols="4">
        <div class="mb-4">
          <div class="mb-2"><strong>Publications</strong></div>
          <div v-if="article.publications.length > 0">
            <div v-for="item in article.publications" :key="'pub-' + item.id">
              <div>
                <b-link :to="{ name: 'NewsItem', params: { id: item.id }}" >{{ item.name }}</b-link>
                <b-button class="mx-2" variant="outline-primary" size="sm" v-on:click="removePublication(item.id)">remove</b-button>
              </div>
              <div class="mb-2"><small>{{ item.publisher }} | {{ moment(item.date).format('YYYY-MM-DD') }} </small></div>
            </div>
          </div>
          <b-form inline>
            <b-form-input class="mr-2" v-model="articleSearch" id="search" placeholder="type id to add" />
            <b-button v-if="articleSearch.length > 4" variant="outline-primary" size="sm" v-on:click="runPublicationSearch">search</b-button>
          </b-form>
          <div v-if="!addingArticle">
            <span v-if="articleSearching"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            <div>
              <div v-if="articleFound.id">
                <b-link :to="{ name: 'NewsItem', params: { id: articleFound.id }}" >{{ articleFound.name }}</b-link>
                <b-button class="mx-2" variant="outline-primary" size="sm" v-on:click="addPublication">add</b-button>
              </div>
            </div>
          </div>
          <div v-if="addingArticle">
            <span><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
          </div>
        </div>
        <div class="mb-4">
          <div class="mb-2"><strong>Events</strong></div>
          <div v-if="article.events.length > 0">
            <div v-for="item in article.events" :key="'event-' + item.id">
              <div>
                <b-link v-on:click="eventShow(item.id)">{{ item.name }}</b-link>
                <b-button class="mx-2" variant="outline-primary" size="sm" v-on:click="eventRemove(item.id)">remove</b-button>
                <b-modal ok-only size="xl" :title="eventToShow.date + ': ' + eventToShow.name" v-model="eventShowModal">
                  <div>{{eventToShow.comments}}</div>
                  <div class="my-4" v-for="publication in eventToShow.publications" :key="'evnt-pub-id-'+publication.id">
                    <div><small>{{publication.publisher}} - {{moment(publication.date).format('YYYY-MM-DD')}}</small></div>
                    <div><b-link :to="{name: 'NewsItem', params: { id: publication.id }}">{{publication.name}}</b-link></div>
                    <div>{{publication.body}}</div>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
          <b-form inline>
            <b-form-datepicker id="event-date" v-model="eventSearchDate" class="mr-2"></b-form-datepicker>
            <b-button variant="outline-primary" size="sm" v-on:click="eventSearch">search</b-button>
          </b-form>
          <div v-if="!eventAdding">
            <span v-if="eventSearching"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            <div v-if="!eventSearching">
              <div v-if="eventsFound.length > 0">
                <div v-for="event in eventsFound" :key="'event-found-' + event.id">
                  <b-link>{{ event.name }}</b-link>
                  <b-button class="mx-2" variant="outline-primary" size="sm" v-on:click="eventAdd(event.id)">add</b-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="eventAdding">
            <span><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
          </div>
        </div>
        <div class="mb-4">
          <select-list
            :editable="false"
            :editAlwaysOn="true"
            :labelDelField="'regtopics.del'"
            :labelLinkingField="'regtopic_id'"
            :labelModel="'regtopic'"
            :linkingModel="'articleregtopic'"
            :name="'Topics'"
            :parentId="article.id"
            :parentLinkingField="'article_id'"
            :relation="'articles'"
          ></select-list>
        </div>
        <div class="mb-4">
          <select-list
            :editable="false"
            :editAlwaysOn="true"
            :labelDelField="'tags.del'"
            :labelLinkingField="'tag_id'"
            :labelModel="'tag'"
            :linkingModel="'articletag'"
            :name="'Tags'"
            :parentId="article.id"
            :parentLinkingField="'article_id'"
            :relation="'articles'"
          ></select-list>
        </div>
        <div class="mb-4">
          <select-list
            :editable="false"
            :editAlwaysOn="true"
            :labelDelField="'risks.del'"
            :labelLinkingField="'risk_id'"
            :labelModel="'risk'"
            :linkingModel="'articlerisk'"
            :name="'Risks'"
            :parentId="article.id"
            :parentLinkingField="'article_id'"
            :relation="'articles'"
          ></select-list>
        </div>
        <div class="mb-2"><strong>PDF</strong></div>
        <div v-if=article.pdf_uploaded class="mb-4">
          <li class="ml-4">{{article.id}}.pdf<b-button @click="deleteFile" variant="danger" size="sm" class="ml-4">Delete</b-button></li>
        </div>
        <div v-if="!article.pdf_uploaded" class="mb-4">
          <b-form-file
            v-model="uploadInfo"
            @input="getUploadUrl"
            ref="uploadForm"
            accept=".pdf"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
          <a v-if=!uploading><b-button v-if="uploadInfo" @click="uploadFile" class="mt-2">Upload!</b-button></a>
          <div v-if=uploading class=mt-4><i class="fa fa-spinner fa-pulse fa-lg fa-fw mt-2"></i>Uploading...</div>
        </div>
        <div>
          <b-modal v-model="showError" v-on:ok=errorClear() :title="errorMessage.title" ok-only centered no-close-on-backdrop no-close-on-esc>
            <b-form-textarea
              class="error"
              plaintext
              rows="4"
              max-rows="8"
              no-resize
              wrap
              :value="errorMessage.error"></b-form-textarea>
          </b-modal>
        </div>
        <div class="mb-4">
          <property-boolean
            :id="article.id"
            field="skip_notifications"
            nameDisplay="Skip notifications"
            model="article"
            :value="article.skip_notifications"
          ></property-boolean>
        </div>
        <div class="mb-4">
          <name-lookup
            :parentId="article.id"
            parentLinkingField="article_id"
            labelLinkingField="impactinitiative_id"
            labelModel="impactinitiative"
            linkable=1
            linkRouteName="ImpactInitiative"
            linkingModel="articleimpactinitiative"
            name="Link initiatives by name or id"
            relation="articles"
          ></name-lookup>
        </div>
        <div class="mb-4">
          <div><strong>Assigned writers</strong></div>
          <div v-if="article.users.length >0">
            <div v-for="userAssigned in article.users" :key="'user-assigned-' + userAssigned.id">
              {{userAssigned.username}}
              <b-button class="mx-2" variant="outline-primary" size="sm" v-on:click="userRemove(userAssigned.id)">remove</b-button>
            </div>
          </div>
          <b-form inline>
            <b-form-input class="mr-2" v-model="userSearchInput" id="searchUser" placeholder="type username to add" />
          </b-form>
          <div v-if="usersLoading">
            <span><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
          </div>
          <div v-if="usersMatched.length >0 & !usersLoading">
            <div v-for="userMatched in usersMatched" :key="'user-matched-' + userMatched.iid">
              <b-link v-on:click="userAssign(userMatched.id)">{{userMatched.id}}</b-link>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div><strong>Assigned reviewers</strong></div>
          <div v-if="article.reviewers.length >0">
            <div v-for="userAssigned in article.reviewers" :key="'reviewer-assigned-' + userAssigned.id">
              {{userAssigned.username}}
              <b-button class="mx-2" variant="outline-primary" size="sm" v-on:click="reviewerRemove(userAssigned.id)">remove</b-button>
            </div>
          </div>
          <b-form inline>
            <b-form-input class="mr-2" v-model="reviewerSearchInput" id="searchReviewer" placeholder="type username to add" />
          </b-form>
          <div v-if="reviewersLoading">
            <span><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
          </div>
          <div v-if="reviewersMatched.length >0 & !reviewersLoading">
            <div v-for="reviewerMatched in reviewersMatched" :key="'reviewer-matched-' + reviewerMatched.iid">
              <b-link v-on:click="reviewerAssign(reviewerMatched.id)">{{reviewerMatched.id}}</b-link>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!loading" class="my-4">
      <b-col>
        <b-button class="mr-2" variant="outline-primary" v-on:click="closeArticle">close</b-button>
        <b-button class="mr-2" variant="outline-primary" v-on:click="articleHistoryShow">history</b-button>
      </b-col>
    </b-row>
    <b-row v-if="articleHistoryVisible" class="my-4">
      <b-col>
        <div class="my-2" v-for="version in articleHistory" :key="'article-history-' + version.id">
          <history-sidebar :version="version" :current="article" />
          {{moment(version.created_at).format('YYYY-MM-DD HH:mm')}} by {{version.created_by}}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { BIconArrowClockwise, BIconArrowCounterclockwise, BIconChatLeftQuote, BIconListOl, BIconListUl, BIconTypeBold, BIconTypeH2, BIconTypeH3, BIconTypeItalic, BIconTypeUnderline, BIconstack, BIconLink, BIconSlashCircle } from 'bootstrap-vue'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Blockquote from '@tiptap/extension-blockquote'
import BulletList from '@tiptap/extension-bullet-list'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import History from '@tiptap/extension-history'

import moment from 'moment'
import axios from 'axios'
import NameLookup from '@/components/sql/NameLookup.vue'
import PropertyBoolean from '@/components/sql/PropertyBoolean.vue'
import SelectList from '@/components/sql/SelectList.vue'
import HistorySidebar from '@/components/workflow/ArticleHistorySidebar'

export default {
  components: { EditorContent, HistorySidebar, SelectList, BIconTypeBold, BIconTypeItalic, BIconArrowClockwise, BIconArrowCounterclockwise, BIconChatLeftQuote, BIconListOl, BIconListUl, BIconTypeH2, BIconTypeH3, BIconTypeUnderline, BIconstack, BIconLink, BIconSlashCircle, NameLookup, PropertyBoolean },
  computed: {
    article: {
      get () {
        return this.$store.state.article
      },
      set (payload) {
        this.$store.commit('setArticle', payload)
      }
    },
    triggerArticleEditorRefresh: {
      get () {
        return this.$store.state.triggerArticleEditorRefresh
      },
      set (payload) {
        this.$store.commit('setTriggerArticleEditorRefresh', payload)
      }
    },
    triggerArticleSave: {
      get () {
        return this.$store.state.triggerArticleSave
      },
      set (payload) {
        this.$store.commit('setTriggerArticleSave', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  props: ['permissions'],
  created: async function () {
    this.$stat.log({ page: 'article editor', action: 'open article editor', model: 'article', model_id: this.$route.params.id })
    this.loading = true
    try {
      if (this.$route.params.id) {
        const article = this.article
        // this.title = article.title
        this.body = article.body
      }
    } catch (e) {
      this.article.title = 'error loading content'
      this.body = 'try again'
    }
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        Blockquote,
        BulletList,
        HardBreak,
        ListItem,
        OrderedList,
        TaskItem,
        TaskList,
        Link,
        Bold,
        Italic,
        Underline,
        History,
        Heading.configure({
          levels: [2, 3]
        })
      ],
      content: this.body,
      onUpdate: () => {
        const html = this.editor.getHTML()
        const article = this.article
        // article.title = this.title
        article.body = html
        this.article = null
        this.article = article
        this.articleHistoryVisible = false
      }
    })
    this.loading = false
  },
  data () {
    return {
      addingArticle: false,
      articleFound: {},
      articleHistory: [],
      articleHistoryVisible: false,
      articleSearch: '',
      articleSearching: false,
      body: '',
      editor: null,
      eventAdding: false,
      eventSearching: false,
      eventSearchDate: moment().format('YYYY-MM-DD'),
      eventShowModal: false,
      eventToShow: {},
      eventsFound: [],
      loading: true,
      reviewersLoaded: [],
      reviewersLoading: false,
      reviewersMatched: [],
      reviewerSearchInput: '',
      saving: false,
      usersLoaded: [],
      usersLoading: false,
      usersMatched: [],
      userSearchInput: '',
      uploadUrl: {},
      uploadInfo: null,
      uploadValid: {},
      showError: false,
      errorMessage: '',
      uploadStatus: '',
      pdfUploaded: false,
      uploading: false
    }
  },
  beforeDestroy: function () {
    this.editor.destroy()
  },
  methods: {
    addPublication: async function () {
      this.addingArticle = true
      try {
        let apiName = 'cosmos'
        let path = '/standard/relationship/articlepublication'
        let params = {
          body: {
            left: { column: 'article_id', id: this.article.id },
            right: { column: 'publication_id', id: this.articleFound.id },
            username: this.user.username
          }
        }
        await this.$Amplify.API.put(apiName, path, params)
        this.$stat.log({ page: 'article editor', action: 'add publication to article', model: 'article', model_id: this.article.id, payload: this.articleFound })
        this.article.publications.push(this.articleFound)
        this.articleFound = {}
        this.articleSearch = ''
      } catch (e) {
        this.$logger.warn('saving error: ' + e)
      }
      this.addingArticle = false
    },
    articleHistoryShow: async function () {
      try {
        const res = await this.$Amplify.API.get('cosmos', `/article/${this.article.id}/history`)
        this.articleHistory = res
        this.articleHistoryVisible = true
      } catch (e) {
        this.$logger.warn('showing event error: ' + e)
      }
    },
    closeArticle: function () {
      this.$router.push({ name: 'WorkflowArticles' })
    },
    eventAdd: async function (id) {
      this.eventAdding = true
      try {
        const params = {
          body: {
            article_id: this.article.id,
            event_id: id,
            username: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/articleevent`, params)
        const event = _.find(this.eventsFound, ['id', id])
        this.article.events.push(event)
        _.remove(this.eventsFound, x => {
          return x.id === id
        })
        this.$stat.log({ page: 'article editor', action: 'add event to article', model: 'article', model_id: this.article.id, payload: { id: id } })
      } catch (e) {
        this.$logger.warn('adding event error' + e)
      }
      this.eventAdding = false
    },
    eventShow: async function (id) {
      try {
        const res = await this.$Amplify.API.get('cosmos', `/event/${id}/full`)
        this.eventToShow = res
        this.eventShowModal = true
      } catch (e) {
        this.$logger.warn('showing event error: ' + e)
      }
    },
    eventRemove: async function (id) {
      try {
        let apiName = 'cosmos'
        let path = '/standard/relationship/articleevent'
        let params = {
          body: {
            left: { column: 'article_id', id: this.article.id },
            right: { column: 'event_id', id: id }
          }
        }
        await this.$Amplify.API.del(apiName, path, params)
        _.remove(this.article.events, (x) => {
          return _.toNumber(x.id) === _.toNumber(id)
        })
        this.$stat.log({ page: 'article editor', action: 'remove event from article', model: 'article', model_id: this.article.id, payload: { id: id } })
        const temp = this.article
        this.article = null
        this.article = temp
      } catch (e) {
        this.$logger.warn('removing events error: ' + e)
      }
    },
    eventSearch: async function () {
      this.eventSearching = true
      try {
        const res = await this.$Amplify.API.get('cosmos', `/events/date/${this.eventSearchDate}`)
        this.eventsFound = res
      } catch (e) {
        this.$logger.warn(e)
      }
      this.eventSearching = false
    },
    moment: function (value) {
      return moment(value)
    },
    removePublication: async function (publicationId) {
      try {
        let apiName = 'cosmos'
        let path = '/standard/relationship/articlepublication'
        let params = {
          body: {
            left: { column: 'article_id', id: this.article.id },
            right: { column: 'publication_id', id: publicationId }
          }
        }
        await this.$Amplify.API.del(apiName, path, params)
        _.remove(this.article.publications, (x) => {
          return _.toNumber(x.id) === _.toNumber(publicationId)
        })
        this.$stat.log({ page: 'article editor', action: 'remove publication from article', model: 'article', model_id: this.article.id, payload: { id: publicationId } })
        const temp = this.article
        this.article = null
        this.article = temp
      } catch (e) {
        this.$logger.warn('saving error: ' + e)
      }
    },
    reviewerAssign: async function (username) {
      this.reviewersLoading = true
      try {
        const params = {
          body: {
            payload: {
              article_id: this.article.id,
              username: username,
              created_by: this.user.username
            }
          }
        }
        const userAssigned = await this.$Amplify.API.put('cosmos', '/article-reviewer', params)
        const users = this.article.reviewers
        users.push(userAssigned)
        this.article.reviewers = users
        this.reviewerSearchInput = ''
        this.$stat.log({ page: 'article reviewr', action: 'assign reviewer', model: 'article', model_id: this.$route.params.id, payload: params.body })
      } catch (e) {
        this.$logger.warn('assigning user error: ' + e)
      }
      this.reviewersLoading = false
    },
    reviewerRemove: async function (id) {
      this.reviewersLoading = true
      try {
        await this.$Amplify.API.del('cosmos', `/article-reviewer/${id}`)
        const users = this.article.reviewers
        _.pullAllBy(users, [{ id: id }], 'id')
        this.article.reviewers = []
        this.article.reviewers = users
        this.$stat.log({ page: 'article reviewer', action: 'remove reviewer', model: 'article', model_id: this.$route.params.id })
      } catch (e) {
        this.$logger.warn('assigning user error: ' + e)
      }
      this.reviewersLoading = false
    },
    runPublicationSearch: async function () {
      this.articleSearching = true
      try {
        this.articleFound = await this.$Amplify.API.get('cosmos', `/standard/publication/item/${this.articleSearch}`)
      } catch (e) {
        this.$logger.warn('saving error: ' + e)
      }
      this.articleSearching = false
    },
    setLink () {
      if (!this.editor.isActive('link')) {
        const url = window.prompt('URL')
        this.editor.chain().focus().setLink({ href: url }).run()
      } else {
        this.editor.chain().focus().unsetLink().run()
      }
    },
    saveArticle: async function () {
      this.saving = true
      this.article.body = this.editor.getHTML()
      try {
        const params = {
          body: {
            article: {
              id: this.article.id,
              title: this.article.title,
              body: this.article.body,
              pdf_uploaded: this.article.pdf_uploaded
            },
            username: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', `/article/${this.article.id}`, params)
        this.$stat.log({ page: 'article editor', action: 'save article', model: 'article', model_id: this.$route.params.id, payload: params.body })
      } catch (e) {
        this.$logger.warn('saving error: ' + e)
      }
      this.saving = false
    },
    getUploadUrl: async function () {
      try {
        this.uploadUrl = {}
        let apiName = 'cosmos'
        let path = '/putObject/insights/pdf/' + this.article.id + '.pdf'
        if (this.uploadInfo.type === 'application/pdf') {
          this.uploadValid = { valid: true }
          this.uploadUrl = await this.$Amplify.API.get(apiName, path)
        } else {
          this.showError = true
          this.errorMessage = 'file type is not valid, must be PDF'
          this.$refs['uploadForm'].reset()
        }
      } catch (e) {
        console.log(e)
      }
    },
    uploadFile: async function () {
      if (this.uploadInfo) {
        this.uploading = true
        this.uploadStatus = ''
        const formData = new FormData()
        formData.append('file', this.uploadInfo)
        const headers = {}
        axios.put(this.uploadUrl.URL, formData, { headers })
          .then((response) => {
            this.uploadStatus = 'Upload successful'
            this.article.pdf_uploaded = true
            this.saveArticle()
            this.uploading = false
          })
          .catch((error) => {
            if (error.response) {
              this.showError = true
              this.errorMessage = { title: 'Upload Error', error: 'Error: ' + error.response.status + '\nMessage: ' + error.response.data }
            } else if (error.request) {
              this.showError = true
              this.errorMessage = { title: 'Upload Error', error: 'Error: ' + error.request }
            } else {
              this.showError = true
              this.errorMessage = { title: 'Upload Error', error: 'Error: ' + error.message }
            }
            console.log(error.config)
          })
      }
    },
    deleteFile: function () {
      this.pdfUploaded = false
      this.uploadInfo = null
      this.article.pdf_uploaded = false
      this.saveArticle()
    },
    errorClear: function () {
      this.uploading = false
      this.uploadInfo = null
      this.$refs.addImageForm.reset()
      // this.$refs.uploadInfo.value = null
    },
    userAssign: async function (username) {
      this.usersLoading = true
      try {
        const params = {
          body: {
            payload: {
              article_id: this.article.id,
              username: username,
              created_by: this.user.username
            }
          }
        }
        const userAssigned = await this.$Amplify.API.put('cosmos', '/article-user', params)
        const users = this.article.users
        users.push(userAssigned)
        this.article.users = users
        this.userSearchInput = ''
        this.$stat.log({ page: 'article editor', action: 'assign user', model: 'article', model_id: this.$route.params.id, payload: params.body })
      } catch (e) {
        this.$logger.warn('assigning user error: ' + e)
      }
      this.usersLoading = false
    },
    userRemove: async function (id) {
      this.usersLoading = true
      try {
        await this.$Amplify.API.del('cosmos', `/article-user/${id}`)
        const users = this.article.users
        _.pullAllBy(users, [{ id: id }], 'id')
        this.article.users = []
        this.article.users = users
        this.$stat.log({ page: 'article editor', action: 'remove user', model: 'article', model_id: this.$route.params.id })
      } catch (e) {
        this.$logger.warn('assigning user error: ' + e)
      }
      this.usersLoading = false
    }
  },
  watch: {
    reviewerSearchInput: async function () {
      let triggerSearch = false
      if (this.reviewerSearchInput.length >= 2) {
        triggerSearch = true
      }
      if (triggerSearch && this.reviewersLoaded.length === 0) {
        this.reviewersLoading = true
        this.reviewersLoaded = await this.$Amplify.API.get('cosmos', `/users`)
        this.reviewersLoading = false
      }
      if (triggerSearch) {
        const temp = this.reviewersLoaded.filter(item => item.id.toUpperCase().includes(this.reviewerSearchInput.toUpperCase()))
        if (this.article.reviewers.length > 0) {
          _.pullAllBy(temp, this.article.reviewers, 'username')
        }
        this.reviewersMatched = _.take(temp, 3)
      }
      if (this.reviewerSearchInput.length < 2) {
        this.reviewersMatched = []
      }
    },
    triggerArticleEditorRefresh: function () {
      const isSame = this.article.body === this.editor.getHTML()
      if (!isSame) {
        this.editor.setContent(this.article.body)
      }
    },
    triggerArticleSave: function () {
      this.saveArticle()
    },
    userSearchInput: async function () {
      let triggerSearch = false
      if (this.userSearchInput.length >= 2) {
        triggerSearch = true
      }
      if (triggerSearch && this.usersLoaded.length === 0) {
        this.usersLoading = true
        this.usersLoaded = await this.$Amplify.API.get('cosmos', `/users`)
        this.usersLoading = false
      }
      if (triggerSearch) {
        const temp = this.usersLoaded.filter(item => item.id.toUpperCase().includes(this.userSearchInput.toUpperCase()))
        if (this.article.users.length > 0) {
          _.pullAllBy(temp, this.article.users, 'username')
        }
        this.usersMatched = _.take(temp, 3)
      }
      if (this.userSearchInput.length < 2) {
        this.usersMatched = []
      }
    }
  }
}
</script>

<style lang="scss">
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #dddddd;

.article_title {
  border-style: none;
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
}

.error {
  white-space: pre;
}

.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  &__svg {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.menubar {

  border: 0px;
  border-style: solid;
  font-size: 20px;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-black;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-black, 0.05);
    }

    &.is-active {
      background-color: rgba($color-black, 0.1);
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }
}

.editor__content {
  border: 0px;
  background-color: white;
  height: 80vh;
  overflow-y: scroll;
  padding: 10px;
}

.ProseMirror {
  border: 0px;
  border-color: white;
  outline-width: 0px;
  outline-style: none;
}

blockquote {
  border-left: 3px solid rgba($color-black, 0.1);
  color: rgba($color-black, 0.8);
  padding-left: 0.8rem;
  font-style: italic;

  p {
    margin: 0;
  }
}
</style>
