<template>
  <span>
    <b-button variant="outline-primary" v-b-toggle:[uid]>show this version</b-button>
    <b-sidebar :id="uid" width="100%" title="Version history" text-variant="black" shadow>
      <div class="px-3 py-2">
        <h5>{{moment(version.created_at).format('YYYY-MM-DD HH:mm')}}</h5>
        <b-container fluid>
          <b-row>
            <b-col>
              <b-alert show variant="danger">OLD VERSION</b-alert>
              <h1>{{version.title}}</h1>
              <div v-html="version.body"></div>
              <b-button class="mr-2" variant="outline-primary" v-on:click="copyToEditor">copy this old version to editor</b-button>
            </b-col>
            <b-col>
              <b-alert show variant="success">MARKED UP CURRENT VERSION</b-alert>
              <h1>{{article.title}}</h1>
              <div v-html="markup"></div>
            </b-col>
         </b-row>
        </b-container>
      </div>
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <strong class="mr-auto">Article history</strong>
          <b-button size="sm" @click="hide">Close</b-button>
        </div>
      </template>
    </b-sidebar>
  </span>
</template>

<script>
import moment from 'moment'

import htmldiff from '@/libs/htmldiff'
import workflow from '@/workflows/article'

export default {
  props: ['current', 'version'],
  components: {
  },
  computed: {
    article: {
      get () {
        return this.$store.state.article
      },
      set (payload) {
        this.$store.commit('setArticle', payload)
      }
    },
    triggerArticleEditorRefresh: {
      get () {
        return this.$store.state.triggerArticleEditorRefresh
      },
      set (payload) {
        this.$store.commit('setTriggerArticleEditorRefresh', payload)
      }
    },
    uid: function () {
      return 'articlesidebar-' + this.version.id
    }
  },
  created: async function () {
    this.markup = htmldiff(this.version.body, this.current.body)
  },
  data () {
    return {
      markup: '',
      moment: moment,
      showMarkUp: false,
      workflow: workflow
    }
  },
  methods: {
    copyToEditor: function () {
      const article = this.current
      article.title = this.version.title
      article.body = this.version.body
      this.article = null
      this.article = article
      this.triggerArticleEditorRefresh = Date.now()
      this.$root.$emit('bv::toggle::collapse', this.uid)
    }
  }
}
</script>

<style>
  del {
    background-color: orangered;
  }
  ins {
    background-color: lightgreen;
  }
</style>
